







































import { Component, Vue } from 'vue-property-decorator'
import DownloadContent from '@/components/info/DownloadContent.vue'
@Component({ components: { DownloadContent } })
export default class DownloadIndex extends Vue {
  text = ''

  get visibleClose (): boolean {
    return this.$route.path === '/download'
  }

  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
