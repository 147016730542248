

























































import { Component, Vue } from 'vue-property-decorator'

@Component
export default class DownloadContent extends Vue {
  get xs (): boolean {
    return this.$vuetify.breakpoint.xs
  }
}
